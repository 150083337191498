body {
  font-family: "Nanum Gothic", sans-serif;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

@page {
  size: 700px 751px;
  /* margin: 49mm 10mm; */
}
